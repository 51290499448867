.button-link {
  display: inline-block;
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.9em;
}

.button-link:hover {
  text-decoration: underline;
}

.base-label {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}