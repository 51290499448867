.ses-header::after {
    content: '';
    position: absolute;
    z-index: 5;
    bottom: 0;
    left: 0;
    right: 0;
    height: 6px;
    background-color: #248fd8;
    background: -webkit-gradient(linear,left top,right top,from(#c25cab),to(#42d2f7));
    background: linear-gradient(to right,#c25cab,#42d2f7);
}

