.ReactTable .rt-th, .ReactTable .rt-td {
    white-space: normal;
}

.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 3px 0 0 rgba(67, 191, 248, 0.8)
}

.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: inset 0 -3px 0 0 rgba(211, 65, 248, 0.8)
}

.ReactTable.-striped .rt-tr.-odd {
    background: rgba(0, 183, 255, 0.03)
}

.ReactTable {
    margin-bottom: 20px;
}
